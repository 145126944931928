import Vue from "vue";
import VueRouter from "vue-router";

import Layout from "@/layout";
Vue.use(VueRouter);

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/HomePage",
    component: Layout,
    children: [
      {
        path: "home",
        component: () => import("@/views/home/index"),
        name: "home",
        /** name 顶部菜单名称
         *  path 当前菜单对应的路径
         *  parentPath 当前菜单父级路径
         */
        meta: {
          name: "课程日历",
          path: "/HomePage/home",
          parentPath: "/HomePage",
        },
      },
      {
        path: "AllCourses",
        component: () => import("@/views/all-courses/index"),
        name: "AllCourses",
        meta: {
          name: "全部课程",
          path: "/HomePage/AllCourses",
          parentPath: "/HomePage",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
