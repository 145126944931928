/* eslint-disable no-debugger */
import router from "./router";
import store from "./store";

const whiteList = ["/login", "/auth-redirect"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in\
  // console.log(JSON.parse(sessionStorage.getItem('store')))
  // 获取老师登录信息
  const hasToken =
    store.state.userInfo.id ||
    JSON.parse(sessionStorage.getItem("store"))?.userInfo?.id;
  // 判断是否存在老师信息，存在跳转，不存在跳登陆页面
  if (hasToken) {
    // 判断将要跳转的页面如果是登录页，清除老师信息如果不是，正常跳转
    if (to.path === "/" || to.path === "/login") {
      store.state.userInfo = {};
      sessionStorage.setItem("store", JSON.stringify({}));
      next(`/login?redirect=${to.path}`);
    } else {
      next();
    }
  } else {
    // 没有老师登录信息时检测跳转页面是否再上方定义过，如果没定义，跳转到指定登陆页面
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
    }
  }
});
