import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import $md5 from "js-md5";

// 路由守卫监听是否登录
import "./permission.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/styles/index.scss"; // global css

Vue.use(ElementUI);
Vue.prototype.$md5 = $md5;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
