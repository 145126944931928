<template>
  <div class="layout-container">
    <div class="left-cantent">
      <img src="@/assets/logo.png" class="left-logo" />
      <div class="head-portrait-box">
        <img src="@/assets/home/head_portrait.png" class="head-portrait" />
        <div class="user-name">{{ userInfo.name }}</div>
      </div>
      <div class="curriculum">
        <img src="@/assets/home/curriculum.png" class="curriculum-img" />
        <div class="curriculum-text">课程</div>
      </div>
      <div class="sign-out" @click="signOut">
        <i class="el-icon-switch-button out-icon"></i>
        <span class="">退出</span>
      </div>
    </div>
    <div class="right-cantent">
      <div class="right-title">
        <div
          class="tab-btn"
          :class="{ active: item.meta.path === key }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="switchTab(item.meta.path)"
        >
          {{ item.meta.name }}
        </div>
      </div>
      <div class="right-main">
        <router-view :key="key" />
      </div>
    </div>
    <!-- 退出登录弹框 -->
    <el-dialog
      title="退出确认"
      :visible.sync="outDialogVisible"
      width="390px"
      custom-class="out-dialog"
      center
    >
      <div class="text-center font-s-16">你是否要退出当前账号？</div>
      <span slot="footer" class="dialog-footer">
        <span
          class="out-dialog-btn cancel-btn"
          @click="outDialogVisible = false"
          >取消</span
        >
        <span class="out-dialog-btn determine-btn" @click="determine"
          >确定</span
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Layout",
  data() {
    return {
      routes: this.$router.options.routes, // 获取全局路由
      meta: this.$route.meta, // 获取当前路由的meta
      tabList: [], // 菜单列表
      userInfo: this.$store.state.userInfo, // 登录用户信息
      outDialogVisible: false, // 退出登录弹框
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  created() {
    // 筛选菜单生成顶部菜单
    this.routeGetTab();
    // console.log(this.userInfo)
  },
  methods: {
    // 根据当前路由的 meta 筛选路由渲染顶部菜单
    routeGetTab() {
      let routes = this.routes; // 全局路由
      let meta = this.meta; // 当前路由
      for (let item of routes) {
        if (item.path === meta.parentPath) {
          let role = this.$store.state.role.id;
          if (role == 3) {
            this.tabList = item.children.filter(
              (item) => item.name != "AllCourses"
            );
          } else {
            this.tabList = item.children;
          }
        }
      }
    },
    // 切换菜单
    switchTab(path) {
      this.$router.push({
        path: path,
      });
      // console.log(path)
    },
    // 退出登录
    signOut() {
      this.outDialogVisible = true;
    },
    // 确定登出
    determine() {
      // 清除登录信息
      this.$store.commit("DEL_USER_INFO");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  width: 100%;
  height: 100%;
  min-width: 1250px;
  display: flex;
  // 模板左边菜单
  .left-cantent {
    width: 120px;
    height: 100%;
    position: relative;
    min-height: 690px;
    background: #232428;
  }
  // 模板右边菜单
  .right-cantent {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f4f4f7;
    // 模板右边顶部tap
    .right-title {
      width: 100%;
      height: 80px;
      min-height: 80px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // 主要内容区域
    .right-main {
      // flex: 1;
      background: #f4f4f7;
      min-height: 610px;
      height: calc(100% - 80px);
    }
  }
}
.left-cantent {
  // logo
  .left-logo {
    width: 100px;
    margin: 22px 10px 0 10px;
  }
  // 头像和名称
  .head-portrait-box {
    text-align: center;
    margin-top: 48px;
    .head-portrait {
      width: 60px;
      margin-bottom: 8px;
    }
    .user-name {
      color: #9c9c9c;
      font-size: 14px;
      font-weight: 400;
    }
  }
  // 课程
  .curriculum {
    text-align: center;
    font-size: 16px;
    color: #fff;
    margin-top: 41px;
    font-weight: 400;
    .curriculum-img {
      width: 16px;
      float: left;
      margin-left: 27px;
      margin-right: 12px;
    }
    .curriculum-text {
      text-align: left;
    }
  }
  .sign-out {
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 58px;
    text-align: center;
    width: 120px;
    cursor: pointer;
    line-height: 20px;
    margin-left: -4px;
    .out-icon {
      margin-right: 10px;
      font-size: 18px;
    }
  }
}

.tab-btn {
  font-size: 20px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #999999;
  height: 34px;
  cursor: pointer;
  margin-top: 6px;
  &:nth-child(1) {
    margin-right: 152px;
  }
}
.active {
  font-weight: 500;
  color: #161616;
  border-bottom: 6px solid#407AFF;
}

.text-center {
  text-align: center;
}
.font-s-16 {
  font-size: 16px;
}
.out-dialog-btn {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  width: 106px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.out-dialog-btn:hover {
  opacity: 0.8;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .cancel-btn {
    border-radius: 4px;
    border: 1px solid #232428;
    color: #333333;
    margin-right: 18px;
  }
  .determine-btn {
    background: #232428;
    border-radius: 4px;
    color: #fff;
  }
}
</style>
<style lang="scss">
.out-dialog {
  border-radius: 4px !important;
  height: 207px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto 0 !important;
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 22px;
    color: #232428;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
