import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    role: 1,
  },
  mutations: {
    SET_USER_INFO: (state, info) => {
      state.userInfo = info;
    },
    DEL_USER_INFO: (state) => {
      state.userInfo = {};
    },
    SET_ROLE: (state, role) => {
      state.role = role;
    },
  },
  actions: {
    setUserInfo({ commit }) {
      commit("SET_USER_INFO");
    },
    delUserInfo({ commit }) {
      commit("DEL_USER_INFO");
    },
  },
  modules: {},
});
